body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div, p {
    cursor: default;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.task-left {
    float: left;
}

.task-right {
    display: inline-block;
    margin-left: 5px;
}

.content {
    width: max-content;
    max-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.menu {
    float: left;
    position: relative;
    z-index: 20;
}

.menu-sub {
    display: none;
}

.menu-main {
    float: left;
    padding: 3px;
}

.menu-main:hover, .menu-sub:focus, .menu-sub:hover {
    background-color: #eeeeee;
}

.menu-main:hover + ul > li > .menu-sub,
ul:hover > li > .menu-sub,
.menu-main:focus + ul > li > .menu-sub, .menu:focus-within .menu-sub {
    display: block;
}

.menu > ul {
    list-style: none;
    position: absolute;
    padding-top: 10px;
}

.menu > ul > li {
    width: max-content;
}

.menu-root > div > ul {
    padding-left: 0;
}

.menu-sub {
    background-color: #ffffff;
    padding: 3px;
    border: thin solid grey;
}

.menu-root {
    display: block;
    height: 20px;
}

.content-body {
    background-color: #fafafa;
    padding: 15px;
}

.content-body-task {
    display: flex;
}

body {
    height: 100%;
    font-size: 14px;
    font-family: Roboto, Arial, sans-serif;
}

#root {
    height: 100%;
}

.htmlNoPages {
    height: 100%;
    align-content: center;
}

.error-message {
    background-color: rgba(255, 0, 0, 0.2);
    white-space: pre-line;
}

.no-task-message {
    background-color: #ffe1af;
    margin: 7px;
}

.user-message {
    background-color: rgba(0, 255, 0, 0.2);
}

.highlight, tbody tr.highlight:nth-child(even), tbody tr.highlight:nth-child(odd) {
    background-color: #fffeaf;
}

.svg-inline--fa {
    color: grey;
}

.antecedence, .side-condition, .consequence {
    text-align: center;
}

.can-drop {
    color: limegreen;
    font-weight: bold;
}

.cannot-drop {
    color: crimson;
    text-decoration: line-through;
}

.deduction-rule {
    padding: 10px;
    display: inline-flex;
}

.navigation {
    float: right;
}

.draggable {
    box-shadow: 1px 1px 1px grey;
    margin: 3px;
    white-space: nowrap;
    display: inline-block;
    cursor: grab;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome
    and Opera */
}

.draggable:hover {
    box-shadow: 2px 2px 1px grey;
}

.drag-indicator > path {
    display: none;
}

.drag-indicator {
    color: lightgray;
}

.draggable:hover > .drag-indicator > path {
    display: inline;
}

.rule-name {
    float: left;
    display: flex;
    align-items: center;
}

.side-condition {
    display: flex;
    align-items: center;
    float: right;
}

.middle-rule-part {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

sub {
    bottom: -0.4em;
    position: relative;
}

td, th {
    padding: 3px;
}

th {
    border-bottom: 1px solid #e0e0e0;
}

.droppable {
    box-shadow: inset 1px 1px 1px grey;
    background-color: #eeeeee;
    margin: 3px;
}

#deduction-table {
    border-collapse: collapse;
}

#deduction-table tr td:first-child,
#deduction-table tr th:first-child {
    text-align: right;
}

.unbreakable {
    white-space: nowrap;
}

.main-link {
    margin: 3px;
}

.panel {
    background-color: #ffffff;
    padding: 3px;
    box-shadow: 1px 1px 1px #DCDCDC;
    margin: 3px;
}

.header {
    height: 50px;
}

.logo {
    float: left;
}

.ga-opt-out {
    font-size: 1em;
    text-align: left;
    color: blue;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    -moz-user-select: text;
    text-decoration: underline;
}

.clickable-icon {
    border: solid 1px #d3d3d3;
    border-radius: 2px;
    padding: 2px;
    margin-left: 2px;
    margin-right: 2px;
}

.closeable-icon {
    float: right;
}

button {
    all: initial;
    font: inherit;
}

button:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

.clickable-icon:hover {
    background-color: #eeeeee;
}

#introduction {
    max-width: 500px;
}

#tGrammar {
    max-width: 250px;
    max-height: 500px;
}

button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.grammar > .draggable, .side-condition > .droppable {
    background-color: lavender;
    border-style: double;
}

#deduction-table > tbody > tr > td > .draggable, .antecedence .droppable {
    background-color: azure;
}

tbody tr:nth-child(even) {
    background-color: #eee;
}

.accessibility-label {
    display: none;
}

#spinner {
    display: block;
    width: 30px;
}
#parsing-table-area > table {
    margin-top: 15px;
    border-spacing: unset;
}

#parsing-table-area > table > tbody > tr > td:nth-child(1),
#parsing-table-area > table > tfoot > tr > td:nth-child(1){
    border-right: 1px solid;
    border-collapse: collapse;
}

#parsing-table-area > table > tfoot > tr > td {
    border-top: 1px solid;
    border-collapse: collapse;
}

.path {
    fill: none;
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.drawArea {
    z-index: 10;
    position: absolute;
    width: 700px;
    height: 200%;
    cursor: crosshair;
    margin-top: 27px;
}

.drawing {
    width: 100%;
    height: 100%;
}

#drawSwitchButton {
    float: right;
}

.colorPalette {
    float:left;
}

.drawingPanel {
    padding: 1px;
}

#floating-div{
    position: absolute;
}

#wInput {
    background-color: #e8d9e8;
    min-width: 100px;
    max-width: 100%;
    width: auto;
}

#wInputMirror {
    visibility: hidden;
    white-space: pre;
}

.droptarget-placeholder {
    height: 0;
    visibility: hidden;
    display: block;
}

#grammarPanel {
    max-height: 200px;
    overflow-y: auto;
}
